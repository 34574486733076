import React, { useEffect, useState } from "react";
import Scroll3DAnimation from "./Scroll3DAnimation";
import { Box } from "@mui/material";
import Footer from "./Footer";
import LoadingSlider from "./LoadingSlider";
import { useGetProductListData, useSetProductChangeCall } from "./service";
import { useParams } from "react-router-dom";
import useSocket from "../hooks/useSocketMessage";
import io from "socket.io-client";
import VariantSelector from "./VariantSelector";

const Home = ({ urldid }) => {
  const { id } = useParams();
  const { data } = useGetProductListData(id || urldid);
  const { mutate: variantChange } = useSetProductChangeCall();

  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const URL = "http://143.110.186.134";
  const socket = io(URL, { autoConnect: false });

  const scrollData = data?.data?.scroll;

  const productData = scrollData?.products;

  const footerData = scrollData?.frames?.footer;
  const headerData = scrollData?.frames?.header;
  const sessionID = data?.data?.sessionID;

  const [showLoading, setShowLoading] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const { currProductKey, isLoadingScreen } = useSocket(socket);

  const detectScreenType = () => {
    const frames = scrollData?.frames;
    const screen_type = frames?.screen_type;

    const aspectRatio = window.innerWidth / window.innerHeight;
    const screenWidth = window.innerWidth;

    let selectedScreenType = screen_type?.find(
      (screen) => aspectRatio >= parseFloat(screen.aspect_ratio_lower_limit)
    );

    if (!selectedScreenType) {
      selectedScreenType = screen_type?.find((screen) => screen.is_default);
    }

    return {
      selectedScreenTypeId: selectedScreenType?.screen_type_id || "02",
      screenWidthFactor: selectedScreenType
        ? screenWidth / parseFloat(selectedScreenType.standard_width)
        : 1,
    };
  };

  let { selectedScreenTypeId, screenWidthFactor } = detectScreenType();

  window.addEventListener("resize", () => {
    const result = detectScreenType();
    selectedScreenTypeId = result.selectedScreenTypeId;
    screenWidthFactor = result.screenWidthFactor;
  });

  const getDimensions = (data, selectedScreenTypeId) => {
    const widthObj = data?.width?.find(
      (w) => w.screen_type_id === selectedScreenTypeId
    );
    const heightObj = data?.height?.find(
      (h) => h.screen_type_id === selectedScreenTypeId
    );

    return {
      width: widthObj ? `${parseFloat(widthObj.width) * 100}vw` : "100vw", // Default to full width
      height: heightObj ? `${parseFloat(heightObj.height) * 100}vh` : "auto", // Default to auto height
    };
  };

  const footerDimensions = getDimensions(footerData, selectedScreenTypeId);

  const HeaderDimensions = getDimensions(headerData, selectedScreenTypeId);

  const handleVariantChange = (variant, propertyId) => {
    const payload = {
      session_id: sessionID,
      message: {
        type: "change_variant",
        message: {
          product_id: productData?.[0]?.product_id,
          property_id: propertyId,
          variant_id: variant,
        },
      },
    };
    variantChange(payload);
  };

  useEffect(() => {
    if (!isSocketConnected && sessionID) {
      const sessionId = sessionID;
      socket.auth = { sessionId };
      socket.connect();
      setIsSocketConnected(true);
    }
  }, [sessionID]);

  useEffect(() => {
    if (!isLoadingScreen && scrollData) {
      const timeout = setTimeout(() => {
        setShowLoading(false);
      }, 3300);

      return () => clearTimeout(timeout);
    }
  }, [isLoadingScreen, scrollData]);

  useEffect(() => {
    if (productData && productData.length > 0) {
      if (productData[0].property && productData[0].property.length > 0) {
        if (
          productData[0].property[0].variants &&
          productData[0].property[0].variants.length > 0
        ) {
          const firstVariant = productData[0].property[0].variants[0];
          const firstPropertyId = productData[0].property[0].property_id;
          setSelectedVariant({
            variantId: firstVariant.varinat_id,
            propertyId: firstPropertyId,
          });
        }
      }
    }
  }, [productData]);

  if (showLoading) {
    return <LoadingSlider />;
  }

  return (
    <>
      <div>
        <Scroll3DAnimation HeaderDimensions={HeaderDimensions} data={data} />
      </div>
      <div>
        {footerData?.is_visible && (
          <Footer footerDimensions={footerDimensions} />
        )}
      </div>
    </>
  );
};

export default Home;
