import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import footerLogo from "../media/logo/footerLogo.png";

const FooterContainer = styled.footer.attrs(({ width, height }) => ({
  style: {
    width,
    height,
  },
}))`
  color: #0b2149;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-top: 8px;
`;

const Logo = styled.img`
  // height: 25px;
  // margin-bottom: 5px;
  // border: 1px solid red;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  max-width: 90%;
  color: #192b61;
  margin-bottom: 10px;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;

  a {
    margin: 0 10px;
    text-decoration: none;
    color: #0b2149;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.div`
  font-size: 12px;
  color: #4b667a;
  margin-top: 20px;
`;

const footerDimensions = { height: "35vh", width: "100%" };

const MobileFooter = () => {
  return (
    <Box>
      <FooterContainer
        width={footerDimensions?.width}
        height={footerDimensions?.height}
      >
        <Logo src={footerLogo} alt="footer logo" style={{ height: "50px" }} />
        <Description>
          Empowering brands with immersive 3D solutions that transform how
          products are showcased, enhancing engagement and driving growth
          effortlessly.
        </Description>

        <FooterBottom>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-start",
              fontSize: "14px",
              fontColor: "#192B61",
              fontFamily: "Outfit",
              fontWeight: "500",
              letterSpacing: "0.1px",
              height: "20px",
            }}
          >
            <a href="/cookie-settings">Cookie Settings</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-of-service">Terms of Service</a>
          </Box>
        </FooterBottom>

        <Copyright>©2024 attic</Copyright>
      </FooterContainer>
    </Box>
  );
};

export default MobileFooter;
