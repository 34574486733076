import React, { useState, useEffect, useRef } from "react";
import Grid2 from "@mui/material/Grid2";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import footerLogo from "../media/logo/footerLogo.png";

const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;
`;

const HeaderContainer = styled(Grid2)`
  background: linear-gradient(
    90deg,
    rgba(242, 229, 235, 1) 0%,
    rgba(228, 216, 222, 1) 30%,
    rgba(218, 232, 235, 1) 75%,
    rgba(216, 227, 240, 1) 100%
  );
  padding: 10px 16px;
  height: ${({ vh }) => `calc(5vh + ${vh * 0.05}px)`};
  position: absolute;
  top: ${({ isHidden }) => (isHidden ? "-100px" : "0")};
  left: 0;
  right: 0;
  transition: top 0.3s ease-in-out;
`;

const Logo = styled.img`
  height: 5vh;
  max-height: 40px;
`;

const IconButton = styled(Grid2)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const NavLinksMobile = styled(Grid2)`
  position: fixed;
  top: ${({ vh }) => `calc(5vh + ${vh * 0.05}px)`};
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(242, 229, 235, 1) 0%,
    rgba(228, 216, 222, 1) 30%,
    rgba(218, 232, 235, 1) 75%,
    rgba(216, 227, 240, 1) 100%
  );
  padding: 0;
  display: ${({ isMenuOpen }) => (isMenuOpen ? "block" : "none")};
  transition: all 0.3s ease-in-out;
`;

const NavLinksContainer = styled(Grid2)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    text-decoration: none;
    color: #1e3a8a;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
`;

const HeaderMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const vhRef = useRef(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < vhRef.current) return;
      vhRef.current = window.innerHeight;
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <HeaderWrapper>
      <HeaderContainer
        container
        justifyContent="space-between"
        alignItems="center"
        vh={vhRef.current}
        isHidden={isHidden}
      >
        <IconButton onClick={toggleMenu}>
          {isMenuOpen ? (
            <CloseIcon sx={{ fontSize: 30, color: "#1e3a8a" }} />
          ) : (
            <MenuIcon sx={{ fontSize: 30, color: "#1e3a8a" }} />
          )}
        </IconButton>
        <Logo src={footerLogo} alt="Logo" />
        <IconButton>
          <PhoneInTalkOutlinedIcon sx={{ fontSize: 30, color: "#1e3a8a" }} />
        </IconButton>
      </HeaderContainer>

      <NavLinksMobile vh={vhRef.current} isMenuOpen={isMenuOpen}>
        <NavLinksContainer>
          <a href="#home" onClick={() => setIsMenuOpen(false)}>
            Home
          </a>
          <a href="#solutions" onClick={() => setIsMenuOpen(false)}>
            Solutions
          </a>
          <a href="#about" onClick={() => setIsMenuOpen(false)}>
            About Us
          </a>
        </NavLinksContainer>
      </NavLinksMobile>
    </HeaderWrapper>
  );
};

export default HeaderMobile;
