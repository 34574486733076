import { useMediaQuery } from "@mui/material";
import React from "react";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";

const Footer = ({ footerDimensions }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile ? (
        <MobileFooter footerDimensions={footerDimensions} />
      ) : (
        <DesktopFooter footerDimensions={footerDimensions} />
      )}
    </>
  );
};

export default Footer;
