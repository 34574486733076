import { InlineWidget } from "react-calendly";

const CalendlyEmbed = () => {
  return (
    <div style={{ height: "600px" }}>
      <InlineWidget url="https://calendly.com/priyanshu-stark2" />
    </div>
  );
};

export default CalendlyEmbed;
