import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { styled } from "styled-components";
import Header from "./Header";
import { useGetProductListData } from "./service";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import CalendlyEmbed from "./CalendlyEmbed";
// import "@fontsource/outfit"; // Import Outfit font

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  font-family: "Outfit", sans-serif;
`;

const Section = styled(Box)`
  margin-bottom: 24px;
`;

const BoldText = styled("strong")`
  font-weight: 600;
`;

const Title = styled(Box)`
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 45px;
  //   letter-spacing: 0.5px;
`;

const Subtitle = styled(Box)`
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #666;
`;

const SectionTitle = styled(Box)`
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.4px;
`;

const BodyText = styled(Box)`
  font-family: "Outfit";
  font-weight: 200;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.6;
`;

const PrivacyPolicy = () => {
  const { id } = useParams();
  const { data } = useGetProductListData(id);
  const scrollData = data?.data?.scroll;
  const headerData = scrollData?.frames?.header;
  const iframeUrl = `http://collections.xculptor.studio/view/EXP1000000086`;
  return (
    <>
      <Header headerData={headerData} />
      <StyledContainer>
        <Section>
          <Title variant="h3" gutterBottom>
            Privacy Policy
          </Title>
          <Subtitle variant="subtitle1">
            <BoldText>Last Updated:</BoldText> Feb 27, 2024
          </Subtitle>
        </Section>

        <Section>
          <BodyText>
            We, <BoldText>Databrain Labs, Inc.</BoldText> ("Databrain",
            "Company", "we", "us", "our") are the owners of the website{" "}
            <BoldText>https://www.usedatabrain.com</BoldText> ("Website") and
            the Company Product ("Product"). The Product is a{" "}
            <BoldText>software as a service (SaaS)</BoldText> platform, which
            acts as an extension to a software or database and extrapolates data
            stored on such software or database to provide{" "}
            <BoldText>data analytics</BoldText>. We respect data privacy rights
            and arecommitted to protecting personal data collected on this
            Website. This Privacy Notice (“Privacy Notice”) setsforth how we
            collect, use and protect the personal data collected onthis Website
            and while providing Services.
            <BoldText>
              PLEASE READ THIS PRIVACY NOTICECAREFULLY. BY CLICKING THE CONSENT
              CHECKBOX AND PROVIDING YOUR CONSENT, YOU CONSENTTO OUR USE OF YOUR
              PERSONAL DATA IN ACCORDANCE WITH THE TERMS OF THIS PRIVACYPOLICY.
              IF YOU DO NOT AGREE TO THE TERMS OF THIS PRIVACY NOTICE, YOU
              MAYWITHDRAW YOUR CONSENT OR ALTERNATIVELY CHOOSE NOT TO PROVIDE
              YOUR PERSONAL DATAON THE WEBSITE. SUCH AN INTIMATION TO WITHDRAW
              YOUR CONSENT CAN BE PROVIDED BYEMAIL TO support@useddatabrain.com.
              IF YOU ARE ACCESSING THE WEBSITE ON BEHALF OF A THIRD PARTY, YOU
              REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH THIRD-PARTY TO
              THE TERMS AND CONDITIONS OF THIS PRIVACY NOTICE AND, IN SUCH AN
              EVENT YOUR USE OF THE WEBSITE SHALL REFER TO USE BY SUCH THIRD
              PARTY. IF YOU DONOT HAVE SUCH AN AUTHORITY (TO PROVIDE ANY
              PERSONAL DATA OF A THIRD PARTY) ORDO NOT AGREE TO THE TERMS OF
              THIS PRIVACY NOTICE, THEN YOU SHOULD REFRAIN FROM USING THE
              WEBSITE.
            </BoldText>
            This Privacy Noticeis an electronic record in the form of an
            electronic contract being compliantand construed in accordance with
            data protection laws of the applicablejurisdictions.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Definitions
          </SectionTitle>
          <BodyText>
            <BoldText>“User(s)”, “you”, “yours”</BoldText> refers to individuals
            accessing the Website or availing the Services.
          </BodyText>
          <BodyText>
            <BoldText>“Personal Data”</BoldText> means data that can identify an
            individual, including name, email, phone, company, location, etc.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Collection of Personal Data
          </SectionTitle>
          <BodyText>
            We may collect your Personal Data in cases including but not limited
            to Account Information, Availing Services, Payment Information,
            Third-Party Data, Demo Requests, and Customer Communications.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Cookies
          </SectionTitle>
          <BodyText>
            We use <BoldText>cookies</BoldText> and similar tracking tools. You
            may disable cookies in your browser settings.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Security
          </SectionTitle>
          <BodyText>
            We implement{" "}
            <BoldText>
              administrative, technical, and physical security measures
            </BoldText>{" "}
            to protect your data.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Your Rights
          </SectionTitle>
          <BodyText>
            You can <BoldText>access, rectify, erase, or restrict</BoldText> the
            use of your Personal Data and withdraw consent at any time.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Governing Laws
          </SectionTitle>
          <BodyText>
            This Privacy Policy is governed by the laws of{" "}
            <BoldText>California, USA</BoldText>.
          </BodyText>
        </Section>

        <Section>
          <SectionTitle variant="h5" gutterBottom>
            Contact Us
          </SectionTitle>
          <BodyText>
            For any questions, contact our Grievance Officer,{" "}
            <BoldText>Vysakh Sreenivasan</BoldText>, at{" "}
            <BoldText>vysakh@useddatabrain.com</BoldText>.
          </BodyText>
        </Section>
      </StyledContainer>
      <div
        style={{
          height: "100vh",
          width: "20%",
          margin: "0px 28px",
          border: "1px solid red",
        }}
      >
        <iframe
          id={`canvas-demoiframe`}
          src={iframeUrl}
          scrolling="no"
          height="100%"
          width="100%"
          frameBorder={0}
        />
      </div>
      <CalendlyEmbed />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
