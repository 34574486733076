import React, { useEffect, useState, useRef } from "react";
import { useSendProgress, useSetProductChangeCall } from "./service";
import Lenis from "lenis";
import Header from "./Header";
import ActiveCard from "./ActiveCard";
import InfiniteBanner from "./InfiniteBanner";
import ReactSliderCard from "./ImageCarousel";
import Grid2 from "@mui/material/Grid2";

const Scroll3DAnimation = ({ HeaderDimensions, data }) => {
  const lenisRef = useRef(null);
  const { mutate: sendData } = useSendProgress();
  const { mutate: variantChange } = useSetProductChangeCall();

  const scrollData = data?.data?.scroll;
  const templates = scrollData?.frames?.templates;

  const sectionData = scrollData?.layers?.find((layer) => !layer?.is_canvas);

  function deepMergeWithPriority(target, source) {
    if (!target || typeof target !== "object") return source;
    if (!source || typeof source !== "object") return target;

    for (const key of Object.keys(source)) {
      if (
        source[key] &&
        typeof source[key] === "object" &&
        !Array.isArray(source[key])
      ) {
        if (!target[key]) target[key] = {};
        target[key] = deepMergeWithPriority(target[key], source[key]);
      } else if (target[key] === undefined) {
        target[key] = source[key];
      }
    }
    return target;
  }

  function mergeSectionWithTemplates(sectionData, templates) {
    if (!sectionData || !templates) return sectionData;

    sectionData.sections?.forEach((section) => {
      section.components?.forEach((component) => {
        if (component.is_template) {
          const template = templates.find(
            (t) => t.template_id === component.template_id
          );
          if (template) {
            Object.assign(
              component,
              deepMergeWithPriority(component, structuredClone(template))
            );
          }
        }

        component.elements =
          component.elements?.map((element) => {
            const elementTemplate = templates.find(
              (t) => t.template_id === element.template_id
            );
            if (elementTemplate) {
              return {
                ...deepMergeWithPriority(
                  element,
                  structuredClone(elementTemplate)
                ),
              };
            }
            return element;
          }) || [];
      });
    });

    return sectionData;
  }

  const processedSectionData = mergeSectionWithTemplates(
    sectionData,
    templates
  );

  const positionType = scrollData?.frames?.position_type;
  const tickerData = scrollData?.frames?.ticker;
  const activeCard = scrollData?.frames?.cards;

  const sessionID = data?.data?.sessionID;
  const product = scrollData?.products;
  const productData = scrollData?.products;

  const detectScreenType = () => {
    const frames = scrollData?.frames;
    const screen_type = frames?.screen_type;

    const aspectRatio = window.innerWidth / window.innerHeight;
    const screenWidth = window.innerWidth;

    let selectedScreenType = screen_type?.find(
      (screen) => aspectRatio >= parseFloat(screen.aspect_ratio_lower_limit)
    );

    if (!selectedScreenType) {
      selectedScreenType = screen_type?.find((screen) => screen.is_default);
    }

    return {
      selectedScreenTypeId: selectedScreenType?.screen_type_id || "02",
      screenWidthFactor: selectedScreenType
        ? screenWidth / parseFloat(selectedScreenType.standard_width)
        : 1,
    };
  };

  let { selectedScreenTypeId, screenWidthFactor } = detectScreenType();

  window.addEventListener("resize", () => {
    const result = detectScreenType();
    selectedScreenTypeId = result.selectedScreenTypeId;
    screenWidthFactor = result.screenWidthFactor;
  });

  const [pageLayout, setPageLayout] = useState([]);

  const [iframeProps, setIframeProps] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const [initialIframeProps, setInitialIframeProps] = useState(null);

  const handleVariantChange = (variant, propertyId) => {
    console.log("variant", variant);
    const payload = {
      session_id: sessionID,
      message: {
        type: "change_variant",
        message: {
          product_id: productData?.[0]?.product_id,
          property_id: propertyId,
          variant_id: variant,
        },
      },
    };
    variantChange(payload);
  };
  const initialPageHeight = useRef(window.innerHeight);
  const initialPageWidth = useRef(window.innerWidth);

  const pageHeight =
    parseFloat(scrollData?.frames.page_height) * initialPageHeight?.current;

  const pageWidth =
    parseFloat(scrollData?.frames.page_width) * initialPageWidth?.current;

  useEffect(() => {
    if (scrollData?.frames && scrollData?.layers) {
      let cumulativeTop = 0;
      let margin = scrollData?.frames?.margin;

      const processedSections = processedSectionData?.sections || [];

      const newPageLayout = scrollData?.layers
        ?.filter((layer) => !layer.is_canvas)
        ?.flatMap((layer) => {
          const layerZIndex = (1 + Number(layer.layer_index)) * 1000000;

          return processedSections
            ?.filter((section) => !section.is_canvas)
            ?.map((section, sectionIndex) => {
              const sectionHeight =
                parseFloat(section.height || 1) * window.innerHeight;
              const x = parseFloat(section.width || 1) * window.innerWidth;
              const sectionWidth = x * (1 - margin * 2);
              let marginLeft = sectionWidth * margin;

              const sectionZIndex = layerZIndex + (sectionIndex + 1) * 10000;

              const components = section.components?.map(
                (component, componentIndex) => {
                  const screenSpecificSize = component.size?.find(
                    (sz) => sz.screen_type_id === selectedScreenTypeId
                  );
                  const screenSpecificPosition = component.position?.find(
                    (pos) => pos.screen_type_id === selectedScreenTypeId
                  );

                  let componentWidth =
                    parseFloat(screenSpecificSize?.x || 0) * sectionWidth;
                  let componentLeft =
                    parseFloat(screenSpecificPosition?.left || 0) *
                    sectionWidth;

                  if (component.ignore_margin) {
                    const marginLeft = sectionWidth * margin;
                    componentWidth *= 1 + margin * 2;
                    componentLeft -= marginLeft;
                  }

                  const componentHeight =
                    parseFloat(screenSpecificSize?.y || 0) * sectionHeight;
                  const componentTop =
                    parseFloat(screenSpecificPosition?.top || 0) *
                    sectionHeight;

                  const componentZIndex =
                    sectionZIndex + (componentIndex + 1) * 100;

                  const elements = component.elements?.map(
                    (element, elementIndex) => {
                      const elementScreenSize = element?.size?.find(
                        (sz) => sz.screen_type_id === selectedScreenTypeId
                      );
                      const elementScreenPosition = element?.position?.find(
                        (pos) => pos.screen_type_id === selectedScreenTypeId
                      );

                      let elementWidth =
                        parseFloat(elementScreenSize?.x || 0) * componentWidth;
                      let elementLeft =
                        parseFloat(elementScreenPosition?.left || 0) *
                          componentWidth +
                        componentLeft;

                      const elementZIndex =
                        componentZIndex + (elementIndex + 1);

                      return {
                        ...element,
                        size: {
                          width: elementWidth,
                          height:
                            parseFloat(elementScreenSize?.y || 0) *
                            componentHeight,
                        },
                        position: {
                          top:
                            parseFloat(elementScreenPosition?.top || 0) *
                              componentHeight +
                            componentTop,
                          left: elementLeft,
                        },
                        zIndex: elementZIndex,
                      };
                    }
                  );

                  return {
                    ...component,
                    size: {
                      width: componentWidth,
                      height: componentHeight,
                    },
                    position: {
                      top: componentTop,
                      left: componentLeft,
                    },
                    elements,
                    zIndex: componentZIndex,
                  };
                }
              );

              const sectionLayout = {
                sectionId: section.section_id,
                sectionIndex: sectionIndex,
                backgroundColor: section.background_color,
                height: sectionHeight,
                width: sectionWidth,
                position: {
                  top: cumulativeTop,
                  left: 0 + marginLeft,
                },
                components,
                zIndex: sectionZIndex,
                product,
              };

              cumulativeTop += sectionHeight;
              return sectionLayout;
            });
        })
        .filter(Boolean)
        .sort((a, b) => a.sectionIndex - b.sectionIndex);

      setPageLayout(newPageLayout);
    }
  }, [scrollData, selectedScreenTypeId, templates]);

  const lastPositionRef = useRef({});

  useEffect(() => {
    if (scrollData?.layers) {
      const canvasLayer = scrollData?.layers?.find((layer) => layer?.is_canvas);

      if (canvasLayer?.sections?.length > 0) {
        const canvasSection = canvasLayer.sections[0];
        const position = canvasSection?.canvas?.position;
        const size = canvasSection?.canvas?.size;

        if (position && size) {
          const screenSpecificPosition = position?.find(
            (pos) => pos.screen_type_id === selectedScreenTypeId
          );

          const screenSpecificSize = size?.find(
            (sz) => sz.screen_type_id === selectedScreenTypeId
          );

          const sectionHeight =
            parseFloat(canvasSection.height || 1) * window.innerHeight;

          const pageWidth = window.innerWidth;
          const margin = scrollData?.frames?.margin || 0;
          const marginPx = pageWidth * margin;

          const canvasLeftRatio = parseFloat(screenSpecificPosition?.left || 0);
          const canvasWidthRatio = parseFloat(screenSpecificSize?.x || 1);

          let canvasLeft = canvasLeftRatio * pageWidth;
          let sectionWidth = canvasWidthRatio * pageWidth;

          if (canvasLayer.is_canvas) {
            sectionWidth = sectionWidth - marginPx;
          }

          // if (canvasLeft + sectionWidth > pageWidth - marginPx) {
          //   canvasLeft = pageWidth - marginPx - sectionWidth;
          // }

          const iframeLeft = canvasLeft;
          const iframeTop =
            parseFloat(screenSpecificPosition?.top || 0) * sectionHeight;

          const initialProps = {
            top: iframeTop,
            left: iframeLeft,
            height: sectionHeight * (screenSpecificSize?.y || 1),
            width: sectionWidth,
          };

          setIframeProps(initialProps);
          setInitialIframeProps(initialProps);
        }
      }
    }
  }, [scrollData, selectedScreenTypeId]);

  const canvasLayer = scrollData?.layers?.find((layer) => layer?.is_canvas);
  const shouldShowCanvasBorder =
    canvasLayer?.sections?.[0]?.canvas?.border?.is_border;

  const scrollStep = scrollData?.frames?.scroll_step;

  const [scrollProgress, setScrollProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const lenis = new Lenis({
      smooth: true,
      lerp: 0.1,
    });

    lenis.on("scroll", ({ progress }) => {
      const canvasLayer = scrollData?.layers?.find((layer) => layer?.is_canvas);
      if (!canvasLayer || !canvasLayer.sections?.length) return;

      setScrollProgress(progress);

      if (scrollStep === 1) {
        return;
      } else if (scrollStep === 0) {
        sendData({
          message: {
            type: "scroll_progress",
            message: {
              scroll_direction: lenis.direction,
              scroll_progress: progress,
            },
          },
          session_id: sessionID,
        });
      } else if (progress > currentStep) {
        sendData({
          message: {
            type: "scroll_progress",
            message: {
              scroll_direction: lenis.direction,
              scroll_progress: progress,
            },
          },
          session_id: sessionID,
        });

        setCurrentStep((prevStep) => Number(prevStep) + Number(scrollStep));
      }
    });

    lenisRef.current = lenis;

    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };
    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, [scrollData, sessionID, sendData, scrollStep, currentStep]);

  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    if (productData && productData.length > 0) {
      if (productData[0].property && productData[0].property.length > 0) {
        if (
          productData[0].property[0].variants &&
          productData[0].property[0].variants.length > 0
        ) {
          const firstVariant = productData[0].property[0].variants[0];
          const firstPropertyId = productData[0].property[0].property_id;
          setSelectedVariant({
            variantId: firstVariant.varinat_id,
            propertyId: firstPropertyId,
          });
        }
      }
    }
  }, [productData]);

  const iframeUrl = `http://scroll.xculptor.io/?scroll=${scrollData?.scroll_id}&session=${data?.data?.sessionID}&scroll_height=2400`;

  const renderComponents = (components) => {
    return components.map((component, index) => {
      const justifyContent =
        component?.justify_content?.find(
          (jc) => jc?.screen_type_id === selectedScreenTypeId
        )?.value || "flex-start";

      const alignItems =
        component?.align_items?.find(
          (ai) => ai?.screen_type_id === selectedScreenTypeId
        )?.value || "flex-start";

      const direction =
        templates
          ?.find(
            (template) => template?.template_id === component?.template_id
            //  &&
            //   template?.template_item === "component" &&
            //   template?.template_item_type === "container"
          )
          ?.direction?.find(
            (dir) => dir?.screen_type_id === selectedScreenTypeId
          )?.value || "column";

      const isBorderEnabled = Array.isArray(component?.is_border)
        ? component?.is_border?.find(
            (ib) => ib?.screen_type_id === selectedScreenTypeId
          )?.value
        : component?.is_border;

      const borderData = Array.isArray(component?.border)
        ? component?.border?.find(
            (b) => b?.screen_type_id === selectedScreenTypeId
          )?.value
        : component?.border;

      const borderColorData = Array.isArray(component?.border_color)
        ? component?.border_color?.find(
            (bc) => bc?.screen_type_id === selectedScreenTypeId
          )?.value
        : component?.border_color;

      const borderRadius = Array.isArray(component?.border_radius)
        ? component?.border_radius?.find(
            (bc) => bc?.screen_type_id === selectedScreenTypeId
          )?.value
        : component?.border_radius;

      const getPropertyValue = (property, defaultValue = null) => {
        if (Array.isArray(property)) {
          let found = property?.find(
            (p) => p.screen_type_id === selectedScreenTypeId
          );

          if (!found) {
            const defaultScreenType = scrollData?.frames?.screen_type?.find(
              (screen) => screen.is_default
            );
            if (defaultScreenType) {
              found = property?.find(
                (p) => p.screen_type_id === defaultScreenType.screen_type_id
              );
            }
          }

          return found ? found.value : defaultValue;
        }
        return property ?? defaultValue;
      };

      const defaultElementAttributes = {
        top: 0,
        left: 0,
        width: 100,
        height: 100,
        backgroundColor: "#fff",
        // zIndex: "10000000",
        border: "none",
        borderRadius: 0,
        color: "#000",
        fontFamily: "sans-serif",
        fontSize: 16,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "left",
        alignContent: "center",
      };

      return (
        <Grid2
          container
          direction={direction}
          key={`component-${index}`}
          style={{
            position: "absolute",
            top: `${component.position.top}px`,
            left: `${component.position.left}px`,

            ...(component.size?.width && {
              width: `${component.size.width}px`,
            }),
            ...(component.size?.height && {
              height: `${component.size.height}px`,
            }),
            zIndex: component.zIndex,

            justifyContent: justifyContent,
            alignItems: alignItems,
            border: isBorderEnabled
              ? `${borderData}px solid ${borderColorData}`
              : "none",
            borderRadius: isBorderEnabled ? borderRadius : "none",

            backgroundColor: getPropertyValue(component.is_transparent, false)
              ? "transparent"
              : getPropertyValue(component.background_color),
          }}
        >
          {component.elements.map((element, idx) => {
            const collectionElement = component?.elements.find(
              (el) => el.type === "collection"
            );

            const experienceId = collectionElement?.name;

            const screenPath =
              element.path?.find(
                (p) => p.screen_type_id === selectedScreenTypeId
              )?.path || "";

            const isElementVisible = getPropertyValue(element.visible, true);
            if (!isElementVisible) return null;

            const justifyContentValue = getPropertyValue(
              element.justify_content,
              ""
            );
            const fontFamily = getPropertyValue(
              element.font_family,
              "sans-serif"
            );

            if (fontFamily) {
              const fontLink = document.createElement("link");
              fontLink.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
                " ",
                "+"
              )}:wght@400;700&display=swap`;
              fontLink.rel = "stylesheet";
              document.head.appendChild(fontLink);
            }

            const iframeCollectionUrl = `http://collections.xculptor.studio/view/${experienceId}`;

            return (
              <Grid2
                item
                key={`component-${index}-element-${idx}`}
                style={{
                  ...(element.size?.width && {
                    width: `${getPropertyValue(element.size.width)}px`,
                  }),
                  ...(element.size?.height && {
                    height: `${getPropertyValue(element.size.height)}px`,
                  }),

                  backgroundColor: getPropertyValue(
                    element.is_transparent,
                    false
                  )
                    ? "transparent"
                    : getPropertyValue(
                        element.background_color,
                        defaultElementAttributes.backgroundColor
                      ),
                  zIndex: element.zIndex,
                  border: getPropertyValue(element.is_border, false)
                    ? `${getPropertyValue(
                        element.border,
                        0
                      )}px solid ${getPropertyValue(
                        element.border_color,
                        "#000"
                      )}`
                    : "none",
                  borderRadius: `${getPropertyValue(
                    element.border_radius,
                    defaultElementAttributes.borderRadius
                  )}px`,
                  color: getPropertyValue(
                    element.font_color,
                    defaultElementAttributes.color
                  ),
                  fontFamily: `'${fontFamily}', '${defaultElementAttributes.fontFamily}'`,
                  fontSize: `${getPropertyValue(
                    element.font_size,
                    defaultElementAttributes.fontSize
                  )}px`,
                  fontWeight: getPropertyValue(
                    element.font_weight,
                    defaultElementAttributes.fontWeight
                  ),
                  fontStyle: getPropertyValue(
                    element.font_style,
                    defaultElementAttributes.fontStyle
                  ),
                  lineHeight: getPropertyValue(
                    element.line_height,
                    defaultElementAttributes.lineHeight
                  ),
                  letterSpacing: getPropertyValue(
                    element.letter_spacing,
                    defaultElementAttributes.letterSpacing
                  ),
                  textAlign: getPropertyValue(
                    element.text_align,
                    defaultElementAttributes.textAlign
                  ),
                  alignContent: getPropertyValue(
                    element.align_content,
                    defaultElementAttributes.alignContent
                  ),
                  margin: `${getPropertyValue(
                    element.margin_top,
                    0
                  )}px ${getPropertyValue(
                    element.margin_right,
                    0
                  )}px ${getPropertyValue(
                    element.margin_bottom,
                    0
                  )}px ${getPropertyValue(element.margin_left, 0)}px`,

                  padding: `${getPropertyValue(
                    element.padding_top,
                    0
                  )}px ${getPropertyValue(
                    element.padding_right,
                    0
                  )}px ${getPropertyValue(
                    element.padding_bottom,
                    0
                  )}px ${getPropertyValue(element.padding_left, 0)}px`,
                }}
              >
                {element.type === "text" && getPropertyValue(element.text, "")}
                {element?.template_item_type === "text" &&
                  getPropertyValue(element.text, "")}
                {element.type === "image" && (
                  <img
                    src={screenPath || ""}
                    alt={getPropertyValue(element.alt_text, "image")}
                    style={{
                      objectFit: getPropertyValue(element.object_fit, "cover"),
                      width: "100%",
                      height: "100%",
                    }}
                  />
                )}
                {element.type === "video" && (
                  <video
                    src={
                      element.path?.find(
                        (p) => p.screen_type_id === selectedScreenTypeId
                      )?.path || ""
                    }
                    // controls
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="hero-vid"
                    preload="none"
                    style={{
                      objectFit: getPropertyValue(element.object_fit, "cover"),
                      width: "100%",
                      height: "100%",
                      borderRadius: `${getPropertyValue(
                        element.border_radius,
                        defaultElementAttributes.borderRadius
                      )}px`,
                    }}
                  />
                )}

                {element.type === "ticker" && (
                  <InfiniteBanner tickerData={tickerData} />
                )}
                {element.type === "card" && (
                  <ActiveCard activeCard={activeCard} />
                )}
                {element.type === "carousel" && <ReactSliderCard />}

                {element.type === "imageMap" && (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={screenPath || ""}
                      alt={getPropertyValue(element.alt_text, "image")}
                      useMap="#image-map"
                      style={{
                        objectFit: getPropertyValue(
                          element.object_fit,
                          "cover"
                        ),
                      }}
                    />
                    <map name="image-map">
                      <area
                        shape="rect"
                        coords="50,50,200,200"
                        href="https://example.com"
                        alt="Example Link"
                        target="_blank"
                      />
                      <area
                        shape="rect"
                        coords="100,100,400,200"
                        href="https://another-link.com"
                        alt="Another Example"
                        target="_blank"
                      />
                    </map>

                    {/* SVG Overlay */}
                    <svg
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                      }}
                    >
                      <rect
                        x="50"
                        y="50"
                        width="150"
                        height="150"
                        fill="none"
                        stroke="red"
                        strokeWidth="2"
                      />
                      <rect
                        x="100"
                        y="100"
                        width="300"
                        height="100"
                        fill="none"
                        stroke="blue"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                )}

                {element.type === "collection" && (
                  <iframe
                    id={`canvas-demoiframe`}
                    src={iframeCollectionUrl}
                    scrolling="no"
                    frameBorder="0"
                    style={{
                      width: element.size?.width
                        ? `${getPropertyValue(element.size.width)}px`
                        : `${component.size?.width || 100}px`,
                      height: element.size?.height
                        ? `${getPropertyValue(element.size.height)}px`
                        : `${component.size?.height || 100}px`,
                    }}
                  />
                )}
              </Grid2>
            );
          })}
        </Grid2>
      );
    });
  };

  const renderSections = () => {
    const headerData = scrollData?.frames?.header;

    return (
      <>
        {/* Render Header if it's visible */}
        {headerData?.is_visible && (
          <div
            style={{
              position: headerData.is_sticky ? "sticky" : "relative",
              zIndex: 5000000,
            }}
          >
            <Header
              headerData={headerData}
              HeaderDimensions={HeaderDimensions}
            />
          </div>
        )}

        {pageLayout.map((section, index) => (
          <div item key={`section-${index}`}>
            <div
              style={{
                backgroundColor: section?.backgroundColor,
                position: "absolute",
                top: `${section.position.top}px`,
                left: `${section.position.left}px`,
                height: `${section.height}px`,
                width: `${section.width}px`,
              }}
            >
              {section?.components && renderComponents(section?.components)}
            </div>
          </div>
        ))}
      </>
    );
  };

  const initialHeight = useRef(window.innerHeight);
  const initialWidth = useRef(window.innerWidth);

  const renderCanvasSections = () => {
    const layers = scrollData?.layers || [];

    return layers
      .filter((layer) => layer.is_canvas)
      .map((layer) => {
        const layerZIndex = (1 + Number(layer.layer_index)) * 1000000;
        return (
          <Grid2 container key={`canvas-layer-${layer.layer_id}`}>
            {layer.sections.map((section, sectionIndex) => {
              const sectionWidth = section.width * initialWidth.current;
              const sectionHeight = section.height * initialHeight.current;

              const sectionZIndex = layerZIndex + (sectionIndex + 1) * 10000;
              const shouldBeTransparent = section.is_transparent;
              const backgroundColor = shouldBeTransparent
                ? "transparent"
                : section.background_color || "transparent";

              return (
                <Grid2
                  container
                  key={`canvas-section-${section.section_id}`}
                  style={{
                    backgroundColor,
                    width: sectionWidth,
                    height: sectionHeight,
                    zIndex: sectionZIndex,
                    position: "absolute",
                  }}
                >
                  {section.components.map((component, componentIndex) => {
                    const componentSize =
                      component.size?.find(
                        (size) => size.screen_type_id === selectedScreenTypeId
                      ) || {};

                    const alignItems =
                      component.align_items?.find(
                        (item) => item.screen_type_id === selectedScreenTypeId
                      )?.value || "flex-start";
                    const justifyContent =
                      component.justify_content?.find(
                        (item) => item.screen_type_id === selectedScreenTypeId
                      )?.value || "flex-start";

                    const border = Array.isArray(component?.border)
                      ? component.border.find(
                          (b) => b.screen_type_id === selectedScreenTypeId
                        ) || {}
                      : {};

                    const componentBorder =
                      component.is_border?.find(
                        (is_border) =>
                          is_border.screen_type_id === selectedScreenTypeId
                      )?.value || false;

                    const componentBorderColor =
                      component.border_color?.find(
                        (border_color) =>
                          border_color.screen_type_id === selectedScreenTypeId
                      )?.value || "black";

                    const borderColor = border?.value || "transparent";

                    const borderWidth = border?.width || 0;
                    const borderRadius = border?.radius || 0;

                    const margin = {
                      top:
                        component.margin_top?.find(
                          (m) => m.screen_type_id === selectedScreenTypeId
                        )?.value || 0,
                      bottom:
                        component.margin_bottom?.find(
                          (m) => m.screen_type_id === selectedScreenTypeId
                        )?.value || 0,
                      left:
                        component.margin_left?.find(
                          (m) => m.screen_type_id === selectedScreenTypeId
                        )?.value || 0,
                      right:
                        component.margin_right?.find(
                          (m) => m.screen_type_id === selectedScreenTypeId
                        )?.value || 0,
                    };

                    const componentWidth =
                      sectionWidth * (componentSize.x || 1);
                    const componentHeight =
                      sectionHeight * (componentSize.y || 1);

                    const position =
                      component.position?.find(
                        (p) => p.screen_type_id === selectedScreenTypeId
                      ) || {};
                    const top = position.top ? position.top * 100 + "%" : "0%";
                    const left = position.left
                      ? position.left * 100 + "%"
                      : "0%";

                    const componentZIndex =
                      sectionZIndex + (componentIndex + 1) * 100;

                    const direction =
                      component?.direction?.find(
                        (dir) => dir?.screen_type_id === selectedScreenTypeId
                      )?.value || "column";

                    const componentPosition =
                      component?.position_type?.find(
                        (pos) => pos?.screen_type_id === selectedScreenTypeId
                      )?.value || "absolute";

                    const animation = scrollData.animations?.find(
                      (anim) =>
                        anim.animation_component === component.component_id
                    );

                    let animatedStyles = {};
                    if (animation) {
                      const { start, end, value } = animation;

                      const animationValue = Array.isArray(value)
                        ? value.find(
                            (v) => v.screen_type_id === selectedScreenTypeId
                          ) || value[0]
                        : value;

                      if (animationValue) {
                        const { top: animTop, left: animLeft } = animationValue;

                        if (scrollProgress < start) {
                          animatedStyles = {
                            top: `${top}`,
                            left: `${left}`,
                          };
                        } else if (
                          scrollProgress >= start &&
                          scrollProgress <= end
                        ) {
                          const animationFactor =
                            (scrollProgress - start) / (end - start);
                          const newTop =
                            parseFloat(top) + animTop * animationFactor * 100;
                          const newLeft =
                            parseFloat(left) + animLeft * animationFactor * 100;

                          animatedStyles = {
                            top: `${newTop}%`,
                            left: `${newLeft}%`,
                          };

                          lastPositionRef.current = {
                            top: newTop,
                            left: newLeft,
                          };
                        } else {
                          if (lastPositionRef.current?.top !== undefined) {
                            animatedStyles = {
                              top: `${lastPositionRef.current.top}%`,
                              left: `${lastPositionRef.current.left}%`,
                            };
                          }
                        }
                      }
                    }

                    return (
                      <Grid2
                        container
                        key={`canvas-component-${component.component_id}`}
                        justifyContent={justifyContent}
                        alignItems={alignItems}
                        style={{
                          width: componentWidth,
                          height: componentHeight,
                          position: componentPosition,
                          top: top,
                          left: left,
                          borderRadius: borderRadius,
                          // margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
                          zIndex: componentZIndex,
                          border: componentBorder
                            ? `${borderColor}px solid ${componentBorderColor}`
                            : "none",
                          ...animatedStyles,
                        }}
                      >
                        {component.elements.map((element, elementIndex) => {
                          const elementSize =
                            element.size?.find(
                              (size) =>
                                size.screen_type_id === selectedScreenTypeId
                            ) || {};

                          const elementBorder =
                            element.is_border?.find(
                              (is_border) =>
                                is_border.screen_type_id ===
                                selectedScreenTypeId
                            )?.value || false;

                          const elementBorderColor =
                            component.border_color?.find(
                              (border_color) =>
                                border_color.screen_type_id ===
                                selectedScreenTypeId
                            )?.value || "black";

                          const elementWidth =
                            componentWidth * (elementSize.x || 1);
                          const elementHeight =
                            componentHeight * (elementSize.y || 1);

                          const elementZIndex =
                            componentZIndex + (elementIndex + 1);

                          const margin = {
                            top:
                              element.margin_top?.find(
                                (m) => m.screen_type_id === selectedScreenTypeId
                              )?.value || 0,
                            bottom:
                              element.margin_bottom?.find(
                                (m) => m.screen_type_id === selectedScreenTypeId
                              )?.value || 0,
                            left:
                              element.margin_left?.find(
                                (m) => m.screen_type_id === selectedScreenTypeId
                              )?.value || 0,
                            right:
                              element.margin_right?.find(
                                (m) => m.screen_type_id === selectedScreenTypeId
                              )?.value || 0,
                          };

                          const borderRadius =
                            element.border_radius?.find(
                              (border_radius) =>
                                border_radius.screen_type_id ===
                                selectedScreenTypeId?.value
                            ) || "50%";

                          return (
                            <Grid2
                              item
                              key={`canvas-element-${element.element_id}`}
                              sx={{
                                width: elementWidth,
                                height: elementHeight,
                                border: elementBorder
                                  ? `${borderColor}px solid ${elementBorderColor}`
                                  : "none",
                                zIndex: elementZIndex,
                                backgroundColor: element.is_background
                                  ? element.background_color
                                  : "transparent",
                              }}
                            >
                              {element.type === "canvas" && (
                                <iframe
                                  id={`canvas-iframe-${elementIndex}`}
                                  src={iframeUrl}
                                  scrolling="no"
                                  height="100%"
                                  width="100%"
                                  frameBorder={0}
                                />
                              )}

                              {element.type === "configurator" && (
                                <Grid2
                                  item
                                  sx={{
                                    // margin: component.margin || "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // border: "1px solid green",
                                  }}
                                >
                                  {/* element */}
                                  <Grid2
                                    container
                                    direction={direction}
                                    justifyContent="center"
                                    alignItems="center"
                                    // spacing={2}
                                    sx={{
                                      background:
                                        component.background_color?.find(
                                          (d) =>
                                            d.screen_type_id ===
                                            selectedScreenTypeId
                                        )?.value || "#EEDD82",
                                      borderRadius: "20px",
                                      // border: "1px solid green",

                                      // gap: "20px",
                                      // margin: "20px",
                                      // overflow: "none",
                                      // whiteSpace: "nowrap",
                                      // padding: "5px",
                                    }}
                                  >
                                    {product?.map((prod) =>
                                      prod.property.map((prop) =>
                                        prop.variants.map((variant) =>
                                          variant.variant_icons.map(
                                            (icon) =>
                                              icon.file_type === "L" && (
                                                <Grid2
                                                  item
                                                  key={`variant-${variant.variant_id}`}
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: borderRadius,
                                                    // backgroundColor: "red",
                                                    // margin: "5px",
                                                    width:
                                                      direction === "row"
                                                        ? componentHeight -
                                                          margin.top -
                                                          margin.bottom
                                                        : componentWidth -
                                                          margin.left -
                                                          margin.right,

                                                    height:
                                                      direction === "row"
                                                        ? componentHeight -
                                                          margin.top -
                                                          margin.bottom
                                                        : componentWidth -
                                                          margin.left -
                                                          margin.right,

                                                    margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
                                                  }}
                                                >
                                                  <img
                                                    key={icon._id}
                                                    src={icon.path}
                                                    alt={`Variant Icon for ${prop.property_name} - ${variant.variant_name}`}
                                                    style={{
                                                      height: "100%",
                                                      width: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: "50%",
                                                      cursor: "pointer",
                                                      transition:
                                                        "transform 0.15s ease-in-out",
                                                      // border: `1px solid ${
                                                      //   selectedVariant?.variantId ===
                                                      //     variant.varinat_id &&
                                                      //   selectedVariant?.propertyId ===
                                                      //     prop.property_id
                                                      //     ? "#1e3a8a"
                                                      //     : "transparent"
                                                      // }`,
                                                    }}
                                                    onClick={() => {
                                                      setSelectedVariant({
                                                        variantId:
                                                          variant.varinat_id,
                                                        propertyId:
                                                          prop.property_id,
                                                      });
                                                      handleVariantChange(
                                                        variant.varinat_id,
                                                        prop.property_id
                                                      );
                                                    }}
                                                  />
                                                </Grid2>
                                              )
                                          )
                                        )
                                      )
                                    )}
                                  </Grid2>
                                </Grid2>
                              )}
                            </Grid2>
                          );
                        })}
                      </Grid2>
                    );
                  })}
                </Grid2>
              );
            })}
          </Grid2>
        );
      });
  };

  return (
    <div>
      {scrollData && (
        <>
          <div>{renderCanvasSections()}</div>
          <div
            style={{
              height: `${pageHeight}px`,
              width: `${pageWidth}px`,
            }}
          >
            <div>{renderSections()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Scroll3DAnimation;
