import React from "react";
import styled, { keyframes, css } from "styled-components";

const scrollAlternate = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const BannerContainer = styled.div`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  overflow: hidden;
  white-space: nowrap;
  background-color: ${({ backgroundColor }) => backgroundColor || "#e6e6fa"};
  ${({ isBorder, borderThickness, borderColor }) =>
    isBorder &&
    css`
      border: ${borderThickness || "1px"} solid ${borderColor || "#000"};
    `}
  padding-top: ${({ paddingTop }) => paddingTop || "0"};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || "0"};
  display: flex;
  align-items: center;
`;

const BannerContent = styled.div`
  display: inline-block;
  white-space: nowrap;
  ${({ isInfinite, scrollSpeed }) =>
    isInfinite &&
    css`
      animation: ${scrollAlternate} ${scrollSpeed || 10}s linear infinite
        alternate;
    `}
  font-size: ${({ fontSize }) => fontSize || "1.5rem"};
  font-weight: ${({ fontWeight }) => fontWeight || "bold"};
  font-family: ${({ fontFamily }) => fontFamily || "sans-serif"};
  color: ${({ textColor }) => textColor || "navy"};
  line-height: ${({ lineHeight }) => lineHeight || "normal"};
`;

const InfiniteBanner = ({ tickerData }) => {
  console.log("tickerData", tickerData);
  // const tickerData = {
  //   texts: [
  //     "Photorealistic 3D Interactions",
  //     "Plug-and-Play Integration",
  //     "Seamless Web Experience",
  //   ],
  //   scroll_speed: 12,
  //   is_border: true,
  //   background_color: "#f0f8ff",
  //   text_color: "#00008b",
  //   font_size: "62px",
  //   font_weight: "500",
  //   font_family: "Outfit, sans-serif",
  //   height: "60px",
  //   width: "100%",
  //   border_color: "#f0f8ff",
  //   border_thickness: "2px",
  //   is_infinite: true,
  //   padding_top: "10px",
  //   padding_bottom: "10px",
  //   gap: "0px",
  //   line_height: "70px",
  // };
  // console.log("tickerData", tickerData);

  return (
    <BannerContainer
      width={tickerData.width}
      height={tickerData.height}
      backgroundColor={tickerData.background_color}
      isBorder={tickerData.is_border}
      borderColor={tickerData.border_color}
      borderThickness={tickerData.border_thickness}
      paddingTop={tickerData.padding_top}
      paddingBottom={tickerData.padding_bottom}
    >
      <BannerContent
        isInfinite={tickerData.is_infinite}
        scrollSpeed={tickerData.scroll_speed}
        fontSize={tickerData.font_size}
        fontWeight={tickerData.font_weight}
        fontFamily={tickerData.font_family}
        textColor={tickerData.text_color}
        lineHeight={tickerData.line_height}
      >
        {tickerData.texts?.join("\u00A0\u00A0\u00A0\u00A0")}
      </BannerContent>
    </BannerContainer>
  );
};

export default InfiniteBanner;
