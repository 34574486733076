import { useMutation, useQuery } from "@tanstack/react-query";
import { httpClient, sendhttpClient } from "../httpClient";

export const useGetProductListData = (url_id) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["allData", url_id],
    queryFn: () => httpClient.get(`/get_scroll?url_id=${url_id}`),
    enabled: !!url_id,
    refetchOnWindowFocus: false,
  });

  return { data, error, isLoading };
};

export const useSendProgress = () => {
  const { mutate, isLoading, data, error } = useMutation({
    mutationFn: async (payload) => {
      const response = await sendhttpClient.post(
        "/player/send_socket_message",
        payload
      );
      return response.data;
    },
  });

  return { mutate, isLoading, data, error };
};

export const useSetProductChangeCall = () => {
  const { mutate, isLoading, data, error } = useMutation({
    mutationFn: async (payload) => {
      const response = await sendhttpClient.post(
        "/player/send_socket_message",
        payload
      );
      return response.data;
    },
  });

  return { mutate, isLoading, data, error };
};
