import { useMediaQuery } from "@mui/material";
import React from "react";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";

const Header = ({ headerData, HeaderDimensions }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile ? (
        <HeaderMobile HeaderDimensions={HeaderDimensions} />
      ) : (
        <HeaderDesktop
          headerData={headerData}
          HeaderDimensions={HeaderDimensions}
        />
      )}
    </>
  );
};

export default Header;
