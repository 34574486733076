import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import { useLocation } from "react-router-dom";
import Privacypolicy from "./components/Privacypolicy";

function NotFound() {
  return <div>Not Found</div>;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeWithParams />} />
        <Route path="/urlId/:id" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

function HomeWithParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urldid = searchParams.get("urldid");
  console.log("urldid", urldid);

  return <Home urldid={urldid} />;
}

export default App;
