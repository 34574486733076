import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, styled } from "@mui/material";

const dotStyles = `
  // .slick-dots {
  //   bottom: 20px; 
  // }

  .slick-dots li button:before {
    font-size: 10px;
    color: black;
    opacity: 0.55;
  }

  .slick-dots li.slick-active button:before {
    color: black;
    opacity: 1;
    font-size: 12px;
  }
`;

const Container = styled(Box)(() => ({
  height: "100%",
  // padding: "15px",
  // margin: "15px",
  position: "relative",
  overflow: "hidden",
  width: "100%",
  // border: "1px solid red",
}));

const ImageCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
  };

  const carouselItems = [
    {
      id: 1,
      color: "#ADD8E6",
      altText: "First Slide Color",
    },
    {
      id: 2,
      color: "#90EE90",
      altText: "Second Slide Color",
    },
    {
      id: 3,
      color: "#FFB6C1",
      altText: "Third Slide Color",
    },
    {
      id: 4,
      color: "#FFFFE0",
      altText: "Fourth Slide Color",
    },
    {
      id: 5,
      color: "#D3D3D3",
      altText: "Fifth Slide Color",
    },
    {
      id: 6,
      color: "#F0E68C",
      altText: "Sixth Slide Color",
    },
  ];

  return (
    <Container>
      <div
        className="carousel-container"
        // style={{ height: "150px", border: "1px solid green" }}
      >
        <style>{dotStyles}</style>
        <Slider {...settings}>
          {carouselItems.map((item) => (
            <div key={item.id} style={{ margin: "0 10px" }}>
              <div
                style={{
                  width: "400px",
                  height: "300px",
                  backgroundColor: item.color,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "black",
                }}
              >
                {item.altText}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
};

export default ImageCarousel;
