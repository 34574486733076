import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import footerLogo from "../media/logo/footerLogo.png";
import { useNavigate } from "react-router-dom";

const HeaderContainer = styled.header.attrs(({ width, height }) => ({
  style: {
    width,
    height,
  },
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(242, 229, 235, 1) 0%,
    rgba(228, 216, 222, 1) 30%,
    rgba(218, 232, 235, 1) 75%,
    rgba(216, 227, 240, 1) 100%
  );
  position: ${({ isSticky }) => (isSticky ? "fixed" : "relative")};
  top: ${({ isSticky }) => (isSticky ? "0" : "auto")};
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isHidden, isSticky }) =>
    isSticky && isHidden ? "translateY(-100%)" : "translateY(0)"};
`;

const Logo = styled.img`
  height: 30px;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 32px;

  a {
    text-decoration: none;
    color: #1e3a8a;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;

    &:hover {
      color: #1e3a8a;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #ffffff;
      position: absolute;
      bottom: -4px;
      left: 0;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
`;

const SolidButton = styled(Button)`
  && {
    background: #1e3a8a;
    color: #ffffff;
    border-radius: 24px;
    padding: 6px 18px;
    font-weight: 500;
    text-transform: none;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    min-width: 140px;
    height: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;

    &:hover {
      background: #1e3a8a;
    }
  }
`;
const HeaderDimensions = { width: "100vw", height: "60px" };
const HeaderDesktop = ({ headerData }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!headerData?.is_sticky) return; // Disable effect if not sticky

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsHidden(currentScrollPos > prevScrollPos && currentScrollPos > 100);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, headerData?.is_sticky]);

  return (
    <HeaderContainer
      isSticky={headerData?.is_sticky}
      isHidden={isHidden}
      width={HeaderDimensions?.width}
      height={HeaderDimensions?.height}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingInline: "5%",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
          <Logo src={footerLogo} alt="Logo" />
          <NavLinks>
            <button
              onClick={() => navigate("/?urldid=home")}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "500",
                color: "#1e3a8a",
              }}
            >
              Home
            </button>

            <a href="#solutions">Solutions</a>
            <a href="#about">About Us</a>
          </NavLinks>
        </Box>
        <SolidButton variant="contained">Request a Demo</SolidButton>
      </Box>
    </HeaderContainer>
  );
};

export default HeaderDesktop;
