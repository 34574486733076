import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import footerLogo from "../media/logo/footerLogo.png";

const FooterContainer = styled.footer.attrs(({ width, height }) => ({
  style: {
    width,
    height,
  },
}))`
  padding: 20px;
  color: #0b2149;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: relative;
  z-index: 100000;
  // border: 1px solid red;
`;

const Logo = styled.img`
  height: 108px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  max-width: 500px;
  color: #192b61;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;

  a {
    margin: 0 10px;
    text-decoration: none;
    color: #0b2149;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.div`
  font-size: 12px;
  color: #4b667a;
  margin-top: 20px;
`;

const footerDimensions = { height: "25vh", width: "100%" };
const DesktopFooter = () => {
  return (
    <Box style={{ paddingInline: "10%" }}>
      <FooterContainer
        width={footerDimensions.width}
        height={footerDimensions.height}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            marginBottom: "24px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Logo src={footerLogo} alt="footer logo" />
          </Box>
          <Box style={{ width: "50%" }}>
            <Description>
              Empowering brands with immersive 3D solutions that transform how
              products are showcased, enhancing engagement and driving growth
              effortlessly.
            </Description>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Box
              style={{
                marginTop: "0px",
                fontSize: "14px",
                fontColor: "#192B61",
                fontFamily: "Outfit",
                fontWeight: "500",
                letterSpacing: "0.1px",
                height: "20px",
              }}
            >
              ©2024 attic
            </Box>
          </Box>
          <FooterBottom style={{ width: "50%" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start",
                fontSize: "14px",
                fontColor: "#192B61",
                fontFamily: "Outfit",
                fontWeight: "500",
                letterSpacing: "0.1px",
                height: "20px",
              }}
            >
              <a href="/cookie-settings">Cookie Settings</a>
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/terms-of-service">Terms of Service</a>
            </Box>
          </FooterBottom>
        </Box>
      </FooterContainer>
    </Box>
  );
};

export default DesktopFooter;
