import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import InfiniteBanner from "./InfiniteBanner";

const Container = styled(Box)(
  ({ containerHeight, containerPadding, containerMargin }) => ({
    display: "flex",
    height: containerHeight,
    padding: containerPadding,
    margin: containerMargin,
    position: "relative",
  })
);

const Section = styled(Box)(
  ({ isActive, bgColor, borderRadius, hoverEffect }) => ({
    flex: isActive ? 7 : 0.5,
    transition: "all 0.5s ease-in-out",
    background: bgColor,
    borderRadius: borderRadius,
    padding: "2rem",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    marginLeft: "-2rem",
    height: "100%",
    "&:first-of-type": {
      marginLeft: 0,
    },
    "&:hover": hoverEffect ? { flex: 7 } : {},
    zIndex: 3,
  })
);

const ContentWrapper = styled(Box)(({ isActive }) => ({
  opacity: isActive ? 1 : 0,
  transition: "opacity 0.3s ease-in-out",
  position: "relative",
  height: "100%",
  visibility: isActive ? "visible" : "hidden",
}));

const VerticalTitle = styled(Typography)({
  position: "absolute",
  bottom: "50%",
  left: "50%",
  transform: "translate(-50%, 50%) rotate(-90deg)",
  transformOrigin: "center",
  whiteSpace: "nowrap",
  color: "#666",
  fontSize: "16px",
  fontWeight: 500,
  letterSpacing: "0.1em",
  textTransform: "uppercase",
});

const ActiveCard = ({ tickerData }) => {
  const activeCard = {
    containerHeight: "600px",
    containerPadding: "15px",
    containerMargin: "15px",
    borderRadius: "24px",
    hoverEffect: true,
    text_color: "#333",
    sections: [
      {
        id: "showcase",
        title: "Showcase",
        sub_title: "Your Products Unlocked in 3D.",
        background_color: "#FBE7E5",
        content_type: "image",
        image_url:
          "https://xculptor-img.blr1.digitaloceanspaces.com/USR1000000002/PRJ1000000001/SCR1000000008/card01.png",
        image_width: "300px",
        image_height: "550px",
        image_position: "center",
        image_object_fit: "contain",
        image_padding: "10px",
        image_margin: "0 auto",
        font_family: "Outfit, sans-serif",
        font_weight: "500",
        font_size: "18px",
        line_height: "1.5",
      },
      {
        id: "collections",
        title: "Collections",
        sub_title: "Shape your product, instantly.",
        background_color: "#E9F5FB",
        content_type: "image",
        image_url:
          "https://xculptor-img.blr1.digitaloceanspaces.com/USR1000000002/PRJ1000000001/SCR1000000008/card02.png",
        image_width: "300px",
        image_height: "550px",
        image_position: "center",
        image_object_fit: "contain",
        image_padding: "10px",
        image_margin: "0 auto",
        font_family: "Outfit, sans-serif",
        font_weight: "500",
        font_size: "16px",
        line_height: "1.4",
      },
      {
        id: "story",
        title: "Story",
        sub_title: "Discover the journey.",
        background_color: "#E8FBF2",
        content_type: "image",
        image_url:
          "https://xculptor-img.blr1.digitaloceanspaces.com/USR1000000002/PRJ1000000001/SCR1000000008/card03.png",
        image_width: "300px",
        image_height: "550px",
        image_position: "center",
        image_object_fit: "contain",
        image_padding: "10px",
        image_margin: "0 auto",
        font_family: "Outfit, sans-serif",
        font_weight: "500",
        font_size: "14px",
        line_height: "1.6",
      },
    ],
  };

  const [activeSection, setActiveSection] = useState(null);

  return (
    <>
      {/* <Box style={{ padding: "2px", border: "1px solid red" }}>
        <InfiniteBanner tickerData={tickerData} />
      </Box> */}
      <Container
        containerHeight={activeCard.containerHeight}
        containerPadding={activeCard.containerPadding}
        containerMargin={activeCard.containerMargin}
      >
        {activeCard.sections.map((section) => (
          <Section
            key={section.id}
            bgColor={section.background_color}
            borderRadius={activeCard.borderRadius}
            hoverEffect={activeCard.hoverEffect}
            isActive={activeSection === section.id}
            onMouseEnter={() => setActiveSection(section.id)}
            onMouseLeave={() => setActiveSection(null)}
          >
            <ContentWrapper
              isActive={activeSection === section.id || activeSection === null}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  mb: 1,
                  fontFamily: section.font_family,
                  fontWeight: section.font_weight,
                  fontSize: section.font_size,
                  lineHeight: section.line_height,
                  color: activeCard.text_color,
                }}
              >
                {section.title}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  mb: 3,
                  maxWidth: "80%",
                }}
              >
                {section.sub_title}
              </Typography>
              {section.content_type === "image" && (
                <img
                  src={section.image_url}
                  alt={section.title}
                  style={{
                    width: section.image_width,
                    height: section.image_height,
                    objectFit: section.image_object_fit,
                    padding: section.image_padding,
                    margin: section.image_margin,
                    display: "block",
                  }}
                />
              )}
            </ContentWrapper>
            {activeSection !== null && activeSection !== section.id && (
              <VerticalTitle>{section.title}</VerticalTitle>
            )}
          </Section>
        ))}
      </Container>
    </>
  );
};

export default ActiveCard;
