import React from "react";
import { Box } from "@mui/material";

const VariantSelector = ({
  productData,
  selectedVariant,
  setSelectedVariant,
  handleVariantChange,
  flexDirection = "column",
}) => {
  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        width: flexDirection === "column" ? "60px" : "auto", // Adjust width for horizontal
        height: flexDirection === "row" ? "60px" : "auto", // Adjust height for horizontal
        background:
          "linear-gradient(90deg, rgba(242, 229, 235, 1) 0%, rgba(228, 216, 222, 1) 30%, rgba(218, 232, 235, 1) 75%, rgba(216, 227, 240, 1) 100%)",
        zIndex: 20000000,
        margin: "0px 10px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: flexDirection, // Dynamic flex direction
        alignItems: "center",
        padding: "4px 0px",
        overflowX: flexDirection === "row" ? "auto" : "visible", // Allow horizontal scrolling
        overflowY: flexDirection === "column" ? "auto" : "visible",
        gap: "4px",
      }}
    >
      {productData?.map((prod) =>
        prod.property.map((prop) =>
          prop.variants.map((variant) =>
            variant.variant_icons.map(
              (icon) =>
                icon.file_type === "L" && (
                  <img
                    key={icon._id}
                    src={icon.path}
                    alt="Variant Icon"
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      padding: "8px",
                      border: `1px solid ${
                        selectedVariant?.variantId === variant.varinat_id &&
                        selectedVariant?.propertyId === prop.property_id
                          ? "#1e3a8a"
                          : "transparent"
                      }`,
                      cursor: "pointer",
                      transition: "transform 0.15s ease-in-out",
                    }}
                    onClick={() => {
                      setSelectedVariant({
                        variantId: variant.varinat_id,
                        propertyId: prop.property_id,
                      });
                      handleVariantChange(variant.varinat_id, prop.property_id);
                    }}
                  />
                )
            )
          )
        )
      )}
    </Box>
  );
};

export default VariantSelector;
